let doubleCheck = false;

export default function initParentLink() {
  if (window.opener != null) {
    setInterval(checkParentStatus, 5000);
  }
  let timer: NodeJS.Timeout | null = null;
  window.addEventListener(
    "message",
    (event) => {
      if (event.data.type === 'parentLeavingChild') {
        parentDriftingApart(event.data.isLeaving);
        if (timer) {
          clearTimeout(timer)
        }
        timer = setTimeout(() => parentDriftingApart(event.data.isLeaving), 5000);
      }
      if (event.data.type === 'ping') {
        event.source?.postMessage({ type: 'pong' }, {targetOrigin: event.origin});
      }
    }
  );
}

export function checkParentStatus() {
  if (window.opener == null) {
    window.close();
  }
}

export function parentDriftingApart(leaving: boolean) {
  if (leaving && doubleCheck) {
    window.close();
    return;
  }
  
  if (leaving) {
    doubleCheck = true;
  }
  else {
    doubleCheck = false;
  }
}